import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import Timer from "./Timer";
import "./Home.css";

const Home = () => {
  return (
    <>
      <div className="launch-page">
        <div className="description">
          <h1>Welcome to the future of investment!</h1>
          <p>
            Our platform is designed to provide you with a stable daily income
            through smart investments. We understand that traditional investment
            methods can be risky and uncertain, which is why we've developed a
            system that helps you grow your wealth in a secure and reliable way.
          </p>

          <p>
            With our platform, you can start investing as little as $100 and
            watch your money grow daily. Our investment experts use advanced
            algorithms and technologies to identify and invest in profitable
            opportunities, so you can sit back and watch your money grow without
            worrying about market fluctuations.
          </p>
          <p>
            Our team is committed to providing the highest level of security and
            transparency, so you can have peace of mind knowing that your
            investments are in good hands.
          </p>
          <p>
            We're excited to launch our platform and invite you to be a part of
            this journey. Start growing your wealth today and secure your
            financial future.
          </p>
          <div className="social-icons">
            <a href="https://twitter.com/ddoc_1">
              <FontAwesomeIcon size="4x" icon={faTwitter} />
            </a>
            <a href="https://www.facebook.com/ddoc21">
              <FontAwesomeIcon size="4x" icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/ddoc_1/">
              <FontAwesomeIcon size="4x" icon={faInstagram} />
            </a>
            <a href="https://www.youtube.com/channel/UCrIR487LeMpR8BgtZEoimHQ">
              <FontAwesomeIcon size="4x" icon={faYoutube} />
            </a>
          </div>
          {/* <Timer seconds={10} /> */}
        </div>
      </div>
    </>
  );
};

export default Home;
